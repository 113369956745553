import React from "react";
// import {ethers} from "ethers";

import Header from "./Header"
import Footer from "./Footer"
import Main from "./Main"
//
// function walletGem() {
//   let wallet;
//   let count = 0;
//   do {
//     wallet = ethers.Wallet.createRandom();
//     count++;
//     if (count % 1000 == 0) {
//       console.log(count);
//     }
//   } while (!wallet.address.toString().toLowerCase().startsWith("0x0"))
//   return wallet.address;
// }

export default function App() {
  return (<div>
    <Header />
    <Main />
    <Footer />
  </div>)
}