import React from "react";
import './Style.css';

//https://www.w3schools.com/howto/howto_css_style_header.asp

export default function Header() {
  return (<header className="header">
    <h1>Prefix Based EVM Vanity Address</h1>
    <p>Seed phrase generator</p>
  </header>)
}